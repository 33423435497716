<template>
  <div>
    <headea></headea>
    <div class="top-img">
      <div class="pyt"><img src="../../assets/images/gswm.jpg" alt="" /></div>
      <div class="cont">
        <div class="cont1">关于我们</div>
        <!-- <div class="cont2">ABOUT US</div> -->
        <!-- <div class="cont3"></div> -->
        <div class="cont4">
          杭州智灵瞳人工智能有限公司成立于2021年，旨在通过团队自主
          研发，精准面向市场需求
          <span
            >提供存储芯片、人工智能芯片，
            以及提供芯片设计、验证和系统解决方案</span
          >
          等服务。公司以
          数字经济第一城杭州为公司总部，并在香港设立研发中心，依托
          两城在科技、人才、贸易的多方面优势，协同发展。
        </div>
        <div class="cont5">
          <!-- <img src="../../assets/images/tykf.png" alt="" /> -->
        </div>
        <div class="cont6">电话：13157913141 \ 18480720159</div>
        <div class="cont6">网址：https://www.smightvision.net</div>
        <div class="cont6">邮箱：business@smightvision.net</div>
        <!-- <img src="../../assets/images/myconter.png" alt="" /> -->
        <!-- <div class="cont1"> <img src="../../assets/images/gymy.png" alt=""></div>
         <div class="cont2"> <img src="../../assets/images/ppj.png" alt=""></div>
         <div class="cont3"> <img src="../../assets/images/df112.png" alt=""></div>
         <div class="cont4"> <img src="../../assets/images/zzz1.png" alt=""></div>
         <div class="cont5"> <img src="../../assets/images/qm.png" alt=""></div>
         <div class="cont6"> <img src="../../assets/images/zzz2.png" alt=""></div> -->
      </div>
    </div>

    <sild></sild>
    <bottom></bottom>
  </div>
</template>

<script>
import headea from "../../components/header/index";
import sild from "../../components/sild/side";
import bottom from "../../components/bottom/bottom";
export default {
  components: {
    headea,
    sild,
    bottom,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.top-img {
  width: 1360px;
  margin: 0 auto;

  position: relative;
  min-width: 1200px;
  margin-top: 120px;
  .pyt {
    height: 600px;
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    img {
      width: 100%;
      height: 40.625rem /* 650/16 */;
      min-height: 650px;
    }
  }
}
.cont {
  width: 1200px;

  margin: 0 auto;

  img {
    width: 120px;
    height: 120px;
  }
}
.cont1 {
  font-size: 30px;
  color: #262e64;
  font-weight: 600;
  margin-top: 70px;
}
.cont2 {
  font-size: 25px;
  color: #262e64;
  font-weight: 600;
  margin: 20px 0;
}
.cont3 {
  width: 80px;
  border: 5px solid #262e64;
}
.cont4 {
  margin-top: 50px;
  color: #5b5b5b;
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 40px;
  span {
    font-size: 25px;
    font-weight: 600;
  }
}
.cont5 {
  margin-top: 100px;
  margin-bottom: 30px;
  img {
    width: 140px;
    height: 140px !important;
    border: 3px solid #262e64;
  }
}
.cont6 {
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 35px;
}
</style>
